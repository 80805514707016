import * as React from "react";
import {useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import FormModal from "../organisms/FormModal";
import LaunchIcon from '@mui/icons-material/Launch';
import EventIcon from '@mui/icons-material/Event';

const LeadButton = (props) => {
    const [openModal, setOpenModal] = useState<boolean>(false)

    const buttonProps = {
        borderRadius: '79px',
        maxWidth: '300px',
        fontWeight: 'bold',
        padding: {
            xs: '8px 16px 8px 16px',
            md: '16px 32px 16px 32px',
        }
    }

    if (!props.website?.live?.functions?.actions?.includes('Leads')) {
        return <></>
    } else {
        return <>
            <Stack direction={"column"}
                   mt={'32px'}
                   spacing={1}
            >
                {!props.website?.live?.functions?.form?.disabled &&
                    <Button
                        key={'button'}
                        variant={"contained"}
                        sx={buttonProps}
                        color={props.color}
                        onClick={() => {
                            setOpenModal(true)
                        }}
                    >
                        {props.website?.live?.functions?.form?.buttonText}
                    </Button>
                }
                {props.website?.live?.functions?.calendly?.redirectTo && <Button
                    key={'button'}
                    variant={"contained"}
                    sx={buttonProps}
                    color={props.color}
                    onClick={() => {
                        window.open(props.website?.live?.functions?.calendly.redirectTo, '_blank');
                    }}
                >
                    <Box mr={1}>{props.website?.live?.functions?.calendly?.buttonText}</Box>
                    <EventIcon fontSize={"medium"}/>
                </Button> || <></>}

                {props.website?.live?.functions?.link?.redirectTo && <Button
                    key={'button'}
                    variant={"contained"}
                    sx={buttonProps}
                    color={props.color}
                    onClick={() => {
                        window.open(props.website?.live?.functions?.link.redirectTo, '_blank');
                    }}
                >
                    <Box mr={1}>{props.website?.live?.functions?.link?.buttonText}</Box>
                    <LaunchIcon fontSize={"medium"}/>
                </Button> || <></>}

            </Stack>
            <FormModal
                open={openModal}
                siteId={props.website.jsonId}
                formDefinition={props.website?.live?.functions?.form}
                closeModal={() => {
                    setOpenModal(false)
                }}
            />
        </>
    }


}
export default LeadButton;